
  ::-webkit-scrollbar {
    width:8px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 0px;
  }
   
  ::-webkit-scrollbar-thumb {
    background:#4d4d4d; 
    border-radius: 0px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background:#4d4d4d; 
  }
  .home
  {
       height:55vh;background-color:black;
  }
  .home img
  {
      height:120px;margin-top:18vh;
  }
  .home button {
   
    border: none;
    outline: none;
    padding:10px;
    padding-left:30px;
    padding-right:30px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.25s;
    margin-top:70px;
    font-weight: bold;
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.1em;
  }
  
  .home button:hover {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.18);
    transform: translateY(-2px);
    transition: 0.25s;
  }
  
   .home .subscribe-button {
    background-color:#f2f2f2;
    color: rgb(20, 20, 20);
  }
  .main-line h2
  {
      font-family: 'Titillium Web', sans-serif; margin-top:40px;
  }
  .main-line h4
  {
      font-family: 'Titillium Web', sans-serif; 
      font-size:19px;
  }
  .products
  {
      margin-top:40px;
  }
  .products h2
  {
     font-family:'Titillium Web', sans-serif; 
     margin-top:30px;
     
  }
  .products h1
  {
     font-family:'Titillium Web', sans-serif; 
     margin-top:30px;
     
  }
  .separator {
    text-align: center;
    margin-top:-40px;
  }
  
  .separator::before,
  .separator::after {
      content: " ";
      display: inline-block;
      border-top: 2px solid #333;
      
      width:3%;
  }
  
  .separator::before {
  margin-top:40px;
  }
  
  section {
    width: 100%;
  
    display: inline-flex;
    margin-top:2vh;
    align-items: center;
    justify-content: center;
  }
  
  section .flex-col {
    padding: 15px;
    font-family:'Titillium Web', sans-serif; 
  }
  
  section .flex-col .cards {
    max-width: 380px;
    height: 257px;
    background-color: #fff;
    padding: 30px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 35px;
    box-shadow: 1px 3px 12px 1px hsl(229, 6%, 66%);
  }
  
  section .flex-col .cards h3 {
    font-size: 1.5rem;
    margin-top:50px;
    
  }
  
  section .flex-col .cards p {
    margin-top: 16px;
    line-height: 2;
  }
  
  section .flex-col .cards img {
    position: absolute;
    height:50px;
    top: 10px;
    left: 30px;
  }
  
  section .four {
    border-top: 5px hsl(212, 86%, 64%) solid;
  }
  .products button {
   
    border: none;
    outline: none;
    padding:10px;
    padding-left:30px;
    padding-right:30px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.25s;
   float:right;
   margin-top:5px;
    font-weight: bold;
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.1em;
  }
  
  .products button:hover {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.18);
    transform: translateY(-2px);
    transition: 0.25s;
  }
  
  .products .subscribe-button4 {
    background-color: #549ef2;
    color:white;
  }
  @media (max-width: 960px) {
    section {
      flex-direction: column;
    }
  
    section .flex-col {
      padding: 0 40px;
    }
  
    header {
      padding: 0 45px 20px;
    }
  }
    .time-date .div
    {
        background-color:#0d0d0d;
        font-family:'Titillium Web', sans-serif;      
        padding:20px;
        color:white;
        
    }
    .time-date h4
    {
        font-size: 17px;
    }
    .time-date .title{
    color: #FFF;
    font-size: 20px;
    text-align: center;
    font-family:'Titillium Web', sans-serif;
    margin-top:25px;
  }
  
  .time-date .title::after{
    content: '';
    display: block;
    height:2px;
    width:30px;
    background-color: #FFF;
    border-radius: 15px;
    margin: 5px auto 0 auto;
  }
  @media (max-width: 425px) {
    header h1 {
      font-size: 1.4rem;
    }
  }
  .footer-distributed {
    background-color:black;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    
    padding: 35px 40px;
    font-family:'Titillium Web', sans-serif;
  }
  
  .footer-distributed .footer-left p {
    color: #8f9296;
    font-size: 14px;
    margin: 0;
  }
  
  .footer-distributed p.footer-links {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 0 10px;
    padding: 0;
    transition: ease .25s;
  }
  
  .footer-distributed p.footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
    transition: ease .25s;
  }
  
  .footer-distributed .footer-links a:before {
    content: "路";
    font-size: 20px;
    left: 0;
    color: #fff;
    display: inline-block;
    padding-right: 5px;
  }
  
  .footer-distributed .footer-links .link-1:before {
    content: none;
  }
  .footer-distributed .footer-links .link-1:hover{
   text-decoration:none;
  }
  .footer-distributed .footer-right {
    float: right;
    margin-top: 12px;
    max-width: 180px;
  }
  
  .footer-distributed .footer-right a {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-left: 3px;
    text-decoration:none;
    transition:all .25s;
  }
  
  .footer-distributed .footer-right a:hover{transform:scale(1.1); -webkit-transform:scale(1.1);text-decoration:none;}
  
  .footer-distributed p.footer-links a:hover{text-decoration:underline;}
  
  .section2
  {
    margin-top: -35px;
  }

  @media (max-width: 600px) {
    .footer-distributed .footer-left, .footer-distributed .footer-right {
      text-align: center;
    }
    .footer-distributed .footer-right {
      float: none;
      margin: 0 auto 20px;
    }
    .footer-distributed .footer-left p.footer-links {
      line-height: 1.8;
    }
    .section2
    {
      margin-top: 0px;
    }
  
  }
  
  @media screen and (max-width:800px)
  {
     .home 
     {
      height:24vh;
     }
     .home img
     {
         height:60px;
         margin-top:8vh;
     }
     .home button 
     {
         margin-top:10px;
     }
  
  .separator::before,
  .separator::after {
      content: " ";
      display: inline-block;
      border-top: 2px solid #333;
      
      width:8%;
  }
  .main-line h2
  {
      font-size:25px;
  }
  section
  {
      margin-top:4vh;
  }
  section .flex-col {
    padding:0px;
  }
  .footer-links
  {
      display:none;
  }
  .section2
    {
      margin-top: 0px;
    }
  }
  @media screen and (max-height:600px)
  {
      .home 
     {
      height:auto;
      
     }
     .home img
     {
         height:60px;
         margin-top:30px;
         margin-bottom:30px;
     }
  }
